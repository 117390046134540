.cancel-button {
  background: #ffffff;
}

.submit-button {
  background: rgba(25, 179, 148, 0.8) !important;
  color: #ffffff;

  transition: all 200ms;
}

.submit-button:hover {
  background: rgba(25, 179, 148, 1) !important;
}

/* #svd-settings {
  display: none;
}

.svc-side-bar {
  display: none;
} */

.spg-search-editor_input {
  height: 26px;
  margin-left: 12px;
}
